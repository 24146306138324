<template>
  <UserSeeds />
</template>

<script>
import UserSeeds from '@/components/UserSeeds.vue';
export default {
  components: {
    UserSeeds: UserSeeds,
  },
};
</script>
